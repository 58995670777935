<template>
<div>
  <video class="myVideo" id="vid" src="/video/video.mp4" autoplay controls></video>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: "Video",
    computed: {
        ...mapGetters(['volume'])
    },
    /* methods: {
        goNext(bl) {
            if(bl) return this.$router.push('welcome');
            this.$router.push('end');
        }
    }, */
    mounted() {
        var self = this;
        var vid = document.getElementById("vid");
        vid.volume = this.volume/100;
        vid.onended = function () {
            self.$router.push('difficulty');
        };
    }
}
</script>

<style scoped>
.myVideo {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
</style>